/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */

import * as Const from '../shared/constants';

// core
import { OnInit } from '@angular/core';
import { DatePipe, CommonModule } from '@angular/common';
import { Component, LOCALE_ID, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';

// material
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginator, MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MaterialModule } from 'app/auth/material/material.module';

// componentes
import { BreadcrumbComponent } from '@shared/components/breadcrumb/breadcrumb.component';
import { FeatherIconsComponent } from '@shared/components/feather-icons/feather-icons.component';

//servicios
import { AlmacenesService } from 'app/services/datos/almacenes.service';
import { UsuarioService } from 'app/services/datos/usuario.service';
import { NotificacionesService } from 'app/services/notificaciones.service';
import { EntradasService } from 'app/services/datos/entradas.service';

//pipes
import { PedidoEstadoPipe } from 'app/pipes/pedido-estado.pipe';

//interfaces 
import { IListaOpciones } from 'app/interfaces/lista-opciones';
import { IApiEntrada } from 'app/entradas/IApiEntrada';

// librerías
import { TableElement, TableExportUtil } from '@shared';
import { environment } from 'environments/environment';
import { Subscription } from 'rxjs';
import { animate, state, style, transition, trigger } from '@angular/animations';
import FuncLib from 'app/providers/funcionesLib';
import { ActivatedRoute } from '@angular/router';
import { CustomPaginatorIntl } from 'app/services/custom-paginator.service';


@Component({
  selector: 'app-entradas-val',
  standalone: true,
  providers: [DatePipe, { provide: LOCALE_ID, useValue: 'es' }, { provide: MatPaginatorIntl, useClass: CustomPaginatorIntl }],
  imports: [CommonModule, FormsModule, BreadcrumbComponent, MaterialModule, MatTableModule, FeatherIconsComponent,
    MatSortModule, MatPaginatorModule, MatFormFieldModule, MatInputModule, PedidoEstadoPipe],
  templateUrl: './entradas-val.component.html',
  animations: [
    trigger('detailExpand', [
      state('collapsed,void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  styleUrl: './entradas-val.component.scss',
})
export class EntradasValComponent implements OnInit {

  // Usar las Constantes también en el template
  Const = Const;

  // configuracion de la tabla
  public summaryPosition: string = localStorage.getItem('summaryPosition') || 'bottom';
  public rowsLimitStr: string = localStorage.getItem('linTablas') || '10';
  public rowsLimit: number = parseInt(this.rowsLimitStr);

  // Decoradores para ordenar y paginación
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;


  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  public funcLib: FuncLib = new FuncLib();

  public imgSubs: Subscription | undefined;
  public fotoDir: string = environment.fotoDir;
  public imgResized: string = localStorage.getItem('imgResized') || '/resize/';

  breadscrums = [{ title: 'Entradas Validadas', items: ['Inicio', 'Entradas'], active: 'Procesadas', }];
  loading: boolean = false;
  opcionesList: IListaOpciones = {}

  displayedColumns: string[] = ['id', 'created_at', 'albaran', 'proveedor', 'totCantidad', 'pales', 'devolucion', 'actions'];
  searchableColumns: string[] = ['id', 'created_at', 'albaran', 'proveedor'];

  columnsToDisplayWithExpand = [...this.displayedColumns, 'expand'];
  expandedElement: any;

  dataSource = new MatTableDataSource<IApiEntrada>([]);

  constructor(
    private entradasSrv: EntradasService,
    public almacenesSrv: AlmacenesService,
    public usuarioSrv: UsuarioService,
    private notificSrv: NotificacionesService,
    public dialog: MatDialog,
    private datePipe: DatePipe,
    private activatedRoute: ActivatedRoute,
  ) {

  }

  ngOnInit(): void {

    // Suscribirse a los parámetros de la url, si envían algo, buscarlo
    this.activatedRoute.params.subscribe(({ buscar }) => {
      if (buscar !== undefined) {
        this.dataSource.filter = decodeURIComponent(buscar);
      }
    });

    this.getListaEntradas();
  }


  getListaEntradas() {
    this.opcionesList.id_cliente = this.usuarioSrv.userdata.cliente!.id;
    this.opcionesList.swValidado = '2';
    this.opcionesList.id_almacen = this.usuarioSrv.userdata.cliente!.alm_por_defecto;

    this.loading = true;
    this.entradasSrv.getLista(this.opcionesList)
      .subscribe(resp => {
        this.loading = false;
        if (resp.error) {
          this.notificSrv.aviso('error', resp.mensaje);
          return
        }

        // Ordenar el array 'detall' de cada entrada por 'artSku'
        resp.data.forEach((entrada: any) => {
          entrada.detall.sort((a: any, b: any) => {
            if (a.artSku < b.artSku) {
              return -1;
            } else if (a.artSku > b.artSku) {
              return 1;
            } else {
              return 0;
            }
          });
        });


        this.dataSource.data = resp.data;
        console.log('__getListaEntradas() ', resp)
      })
  }

  // Buscar 
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value; 
    if (filterValue === undefined) return

    if (this.searchableColumns && this.searchableColumns.length > 0) {
      this.dataSource.filterPredicate = (data: any, filter: string) => {
        const searchableData = this.searchableColumns.map(campo => data[campo]).join(' ');
        return searchableData.toLowerCase().includes(filter);
      };
      this.dataSource.filter = filterValue.toLowerCase();
    }
  }



  highlightSearch(text: string, searchTerm: string): string {

    if (text == null) return '';
    if (!searchTerm) return text;

    const regex = new RegExp(searchTerm, 'gi');
    const highlightedText = text.replace(regex, match => `<span class="resaltado">${match}</span>`);
    return highlightedText;
  }


  transformDate(date: string | null): string {
    //  return date !== null ? this.datePipe.transform(date, 'shortDate') ?? '' : '';    
    if (!date) return '';
    return date !== null ? this.datePipe.transform(date, 'yyyy-MM-dd') ?? '' : '';
  }




  /// AUX /// 

  descargaAlbaranClick(entrada: IApiEntrada) {
    console.log(`__descargaAlbaran() ${entrada}`)
    const urlPdf: string = environment.pdfDir + '/albaranEntrada/' + entrada.pdf_albaran;
    window.open(urlPdf, '_blank');
  }


  // export table data in excel file
  exportExcel() {
    const exportData: Partial<TableElement>[] =
      this.dataSource.filteredData.map((x) => ({
        'Id': x.id,
        'Albarán': x.albaran,
      }));

    console.log('exportData', exportData)
    if (!exportData.length) {
      this.notificSrv.aviso('warning', 'No hay datos a exportar en XLSX')
      return
    }
    TableExportUtil.exportToExcel(exportData, 'Entradas Validadas');

  }



  // Totales 

  getTotalCantidad(): number {
    return this.dataSource.filteredData.reduce((total, rec) => {
      const cantidadNum = rec.totCantidad ? parseInt(rec.totCantidad as unknown as string, 10) : 0; // Aserción de tipo con 'as'
      return total + cantidadNum;
    }, 0);
  }

  getTotalPales(): number {
    return this.dataSource.filteredData.reduce((total, rec) => {
      const cantidadNum = rec.pales ? parseInt(rec.pales as unknown as string, 10) : 0; // Aserción de tipo con 'as'
      return total + cantidadNum;
    }, 0);
  }




  // Método para determinar si una fecha es anterior a hoy
  isExpired(fecha: string): boolean {
    if (!fecha) return false

    const hoy = new Date();
    return new Date(fecha) < hoy;
  }

}

