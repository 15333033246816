/* eslint-disable @typescript-eslint/no-explicit-any */
// Módulos
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

// Servicios
import { UsuarioService } from './usuario.service';
import { NotificacionesService } from '../notificaciones.service';

// librerías
import { environment } from 'environments/environment';

// Interfaces 
import { IApiResp } from "app/interfaces/api-resp";
import { IListaOpciones } from 'app/interfaces/lista-opciones';
import { IPedido } from 'app/interfaces/pedido';


@Injectable({
  providedIn: 'root'
})
export class PedidosService {

  apiURL: string = environment.apiURL;
  public selected: any;                   // Línea seleccionada

  constructor(
    private http: HttpClient,
    private usuarioSrv: UsuarioService,
    private router: Router,
    private notificacionesSrv: NotificacionesService,
  ) { }


  getPedido(id: number) {
    const path = `${this.apiURL}/pedidos/list/${id}`;
    const lista = this.http.get<IApiResp>(path)
    return lista;
  }


  getLista(_opciones: IListaOpciones = {}) {
    // Devulve la lista filtrada
    const path = `${this.apiURL}/pedidos/listFiltro`;

    const data = {
      id: _opciones.id || null,
      id_almacen: _opciones.id_almacen || null,
      id_cliente: _opciones.id_cliente || null,
      id_articulo: _opciones.id_articulo || null,
      id_ubicacion: _opciones.id_ubicacion || null,
      id_campanya: _opciones.id_campanya || null,
      swCompleto: _opciones.swCompleto || null,

      estado: _opciones.estado || null,
      totalesPorEstado: _opciones.totalesPorEstado || null,

      recDesde: _opciones.recDesde || null,
      recCuantos: _opciones.recCuantos || null,
    };

    return this.http.post<IApiResp>(path, data)
  }



  downloadAlbaran(id_pedido: number) {
    // devuelve la ruta a un pdf con el albarán
    const path = `${this.apiURL}/pedidos/downloadAlbaran/${id_pedido}`;
    const resp = this.http.get<IApiResp>(path)
    return resp;
  }



  new(formData: any) {
    // Añade un pedido
    const path = `${this.apiURL}/pedidos/new`;
    return this.http.post<IApiResp>(path, formData)
  }

  update(pedido: IPedido) {
    // Actualiza un pedido
    const path = `${this.apiURL}/pedidos/update/${pedido.id}`;
    return this.http.post<IApiResp>(path, pedido)
  }


  delete(id: number) {
    const path = `${this.apiURL}/pedidos/delete/${id}`;
    return this.http.get<IApiResp>(path)
  }



  solicitaAccion(data: any) {
    // Modificar pedido:(accion_cliente= (1/2), accion_cliente_fecha=NOW() , aviso_cliente=NULL)
    // Enviar email al cliente y al administrador
    const path = `${this.apiURL}/pedidos/solicitaAccion`;
    return this.http.post<IApiResp>(path, data)
  }



  ///// AUX ////




}
