// services/interceptors/api-error.interceptor.ts
// Manejo centralizado de errores de llamadas a la API


import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError, throwError } from 'rxjs';

// servicios
import { NotificacionesService } from '../notificaciones.service';
import { UsuarioService } from '../datos/usuario.service';

export const apiErrorInterceptor: HttpInterceptorFn = (req, next) => {
  const notifSrv = inject(NotificacionesService);
  const usuarioSrv = inject(UsuarioService);

  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      usuarioSrv.logOut();
      console.log('Error completo:', error);

      // Manejar diferentes formatos de error posibles
      const errorMessage = error.error?.mensaje || error.error || error.message || 'Error desconocido';

      try {
        // Intentar mostrar la notificación verificando que no falle
        notifSrv.aviso('error', errorMessage);
      } catch (notifError) {
        console.error('Error al mostrar la notificación:', notifError);
      }

      // Devolver el error para que la cadena continúe
      return throwError(() => new Error(error.message));
    })
  );
};