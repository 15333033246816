import { animate, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

// módulos 
import { MaterialModule } from 'app/auth/material/material.module';
import { IPedidoExterno } from 'app/interfaces/pedido_externo';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-info-pedido',
  standalone: true,
  imports: [MaterialModule, CommonModule],
  templateUrl: './info-pedido.component.html',
  styleUrl: './info-pedido.component.scss',
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('600ms ease-in-out', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        animate('600ms ease-in-out', style({ opacity: 0 }))
      ])
    ])
  ]
})
export class InfoPedidoComponent implements OnInit {
  @Input() public pedidoExterno?: IPedidoExterno = {};

  public importDir: string = environment.importDir;
  creadoDesde: string = 'Manualmente desde esta intranet';

  constructor() {

  }

  ngOnInit(): void {

    console.log('__InfoPedidoComponent() this.pedidoExterno', this.pedidoExterno);
    if (this.pedidoExterno?.tipo === 'I') this.creadoDesde = 'Desde importación automática';
  }


}