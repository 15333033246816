<div class="addContainer p-2 fade show">

    <div class="modalHeader">
        <div class="p-2">
            <h4>Importar artículos</h4>
        </div>

        <button mat-icon-button
                (click)="cancelarClick()"
                class="modal-close-button"
                aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </div>

    <div mat-dialog-content>
        <div class="mb-3">
            Importa automáticamente artículos a través de una plantilla de Excel. Sigue los siguientes pasos:
        </div>

        <div class="importItem">
            <div class="pasos">1</div>
            <span class="importItem">
                <a href="{{pantillasDir}}/import_articulos_plantilla.xlsx">Descárgate la plantilla (.XLS).</a>
                Usa nuestra plantilla para asegurar que toda la información se importa correctamente.
            </span>
        </div>

        <div class="importItem">
            <div class="pasos">2</div>
            <span>
                Rellena las columnas con la información, sin dejar ninguna fila en blanco. Rellenar las columnas <span style="color: #ffc107">amarillas</span> es obligatorio y no se debe alterar la tabla.
            </span>
        </div>

        <div class="importItem">
            <div class="pasos">3</div>
            <span>
                Una vez que termines puedes subir el archivo desde tu ordenador o soltarlo en la barra.
            </span>
        </div>



        <span class="fw-lighter">
            Selecciona el archivo desde tu ordenador para cargarlo, o arrástralo y suéltalo en la barra.
        </span>
        <div>
            <div class="fileBtn">
                <input type="file"
                       (change)="archivoSeleccionadoChange($event)"
                       accept=".xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                       class="form-control form-control-lg">
            </div>

            <div class="mt-3 d-flex justify-content-between">

                <button [disabled]="loading"
                        (click)="cancelarClick()"
                        mat-raised-button
                        data-dismiss="modal">Cancelar</button>

                <button type="button"
                        [disabled]="!archivoSeleccionado || loading"
                        mat-raised-button
                        (click)="enviarClick()"
                        color="primary">Importar</button>
            </div>

        </div>

    </div>

</div>