import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MaterialModule } from 'app/auth/material/material.module';

@Component({
  selector: 'app-modal-privacidad',
  standalone: true,
  imports: [MaterialModule],
  templateUrl: './modal-privacidad.component.html',
  styleUrl: './modal-privacidad.component.scss'
})
export class ModalPrivacidadComponent {
  constructor(
    public dialogRef: MatDialogRef<ModalPrivacidadComponent>
  ) { }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
