<div class="modal-container">
    <h2 mat-dialog-title
        class="modal-title">Política de cookies
    </h2>

    <mat-dialog-content class="modal-content">
        <h3>Política de cookies</h3>

        <p>
            La presente política de cookies tiene por finalidad informarte de manera clara y precisa sobre las cookies que se
            utilizan en la página web de <b>InnovaCen</b>.
        </p>

        <h4>¿Qué son las cookies?</h4>

        <p>
            Una cookie es un pequeño fragmento de texto que los sitios web que visitas envían al navegador y que permite que el
            sitio web recuerde información sobre tu visita, como contenido en pantalla, colores y otras opciones, con el fin de facilitar tu
            próxima visita y hacer que el sitio te resulte más útil. Las cookies desempeñan un papel muy importante y contribuyen a
            tener una mejor experiencia de navegación para ti.
        </p>

        <h4>Tipos de cookies</h4>
        <p>
            Según quién sea la entidad que gestione el dominio desde dónde se envían las cookies y se traten los datos que se obtengan,
            se pueden distinguir dos tipos: cookies propias y cookies de terceros.
            En esta web <span style="color: green">NO utilizamos cookies de terceros</span>, solo propias.
        </p>

        <p>
            Existe también una segunda clasificación según el plazo de tiempo que permanecen almacenadas en el navegador del
            cliente, pudiendo tratarse de cookies de sesión o cookies persistentes.
        </p>

        <p>
            Para más información a este respecto puedes consultar la <a href="https://www.agpd.es/portalwebAGPD/CanalDelCiudadano/pagina.php?id=1"
               target="_blank">Guía sobre el uso de las cookies de la Agencia Española de Protección de Datos</a>.
        </p>

        <h4>Cookies utilizadas en la web </h4>
        <i>Nombre de la cookie y su valor por defecto:</i><br>
        <ul>
            <li>buscarClientesEnPedidos: si</li>
            <li>choose_logoheader: logo-white</li>
            <li>choose_skin: theme-white</li>
            <li>choose_skin_active: white</li>
            <li>collapsed_menu: false</li>
            <li>isRtl: false</li>
            <li>linTablas: 10</li>
            <li>listStockImg: si</li>
            <li>menuOption: menu_light</li>
            <li>theme: light</li>
        </ul>

        <p>
            Otra de las cookies que se descargan es una cookie de tipo técnico denominada <b>TOKEN</b>. Esta cookie permite
            almacenar un identificador único por sesión a través del que es posible vincular datos necesarios para posibilitar la
            navegación en curso.
        </p>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <button mat-button
                color="primary"
                (click)="closeDialog()">Cerrar</button>
    </mat-dialog-actions>
</div>