import { RouteInfo } from './sidebar.metadata';

const baseRoutes: RouteInfo[] = [
  {
    path: 'dashboard/panel',
    title: 'Panel',
    icon: 'pie-chart',
    class: '',
    groupTitle: false,
    submenu: [],
  },
  {
    path: 'stock',
    title: 'Stock',
    icon: 'package',
    class: '',
    groupTitle: false,
    submenu: [],
  },
  {
    path: 'articulos',
    title: 'Artículos',
    icon: 'trello',
    class: '',
    groupTitle: false,
    submenu: [],
  },
  {
    path: 'clientes',
    title: 'Clientes',
    icon: 'users',
    class: '',
    groupTitle: false,
    submenu: [],
  },
  {
    path: '',
    title: 'Pedidos',
    icon: 'clipboard',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: 'solicitud-pedidos',
        title: 'Solicitudes',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'pedidos',
        title: 'Procesados',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
    ]
  },
  {
    path: '',
    title: 'Entradas',
    icon: 'truck',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: 'entradas',
        title: 'Solicitudes',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'entradas-validadas',
        title: 'Procesadas',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
    ]
  }
];


// Verificar si se debe agregar la opción "Clientes"
// if (localStorage.getItem('buscarClientesEnPedidos') === 'si') {
//   const clientesRoute: RouteInfo = {
//     path: 'clientes',
//     title: 'Clientes',
//     icon: 'users',
//     class: '',
//     groupTitle: false,
//     submenu: [],
//   };

//   // Buscar la posición de "Artículos" y agregar "Clientes" después
//   const index = baseRoutes.findIndex(route => route.path === 'articulos');
//   if (index !== -1) {
//     baseRoutes.splice(index + 1, 0, clientesRoute);
//   }
// }



export const ROUTES: RouteInfo[] = baseRoutes;


