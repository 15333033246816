/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @angular-eslint/use-lifecycle-interface */

import { Component, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
import { CommonModule, DatePipe, formatDate } from '@angular/common';

// componentes 
import { BreadcrumbComponent } from "../shared/components/breadcrumb/breadcrumb.component";

// módulos  
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MaterialModule } from 'app/auth/material/material.module';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { FormsModule } from '@angular/forms';
import { MatPaginator, MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';

// interfaces 
import { IListaOpciones } from 'app/interfaces/lista-opciones';
import { IApiDataPedido } from './iDataPedido';

// servicios
import { PedidosService } from 'app/services/datos/pedidos.service';
import { UsuarioService } from 'app/services/datos/usuario.service';
import { AlmacenesService } from 'app/services/datos/almacenes.service';
import { NotificacionesService } from 'app/services/notificaciones.service';

// librerías y pipes
import { TableElement, TableExportUtil } from '@shared';
import { PedidoEstadoPipe } from 'app/pipes/pedido-estado.pipe';
import { MatDialog } from '@angular/material/dialog';
import { FeatherIconsComponent } from '@shared/components/feather-icons/feather-icons.component';
import { environment } from 'environments/environment';
import { PedidoDetallDialogComponent } from './dialogs/pedido-detall-dialog/pedido-detall-dialog.component';
import { ActivatedRoute } from '@angular/router';
import { CustomPaginatorIntl } from 'app/services/custom-paginator.service';



@Component({
  selector: 'app-pedidos',
  standalone: true, templateUrl: './pedidos.component.html',
  styleUrl: './pedidos.component.scss',
  providers: [DatePipe, { provide: LOCALE_ID, useValue: 'es' }, { provide: MatPaginatorIntl, useClass: CustomPaginatorIntl }],
  imports: [CommonModule, FormsModule, BreadcrumbComponent, MaterialModule, MatTableModule, FeatherIconsComponent,
    MatSortModule, MatPaginatorModule, MatFormFieldModule, MatInputModule,
    PedidoEstadoPipe]
})
export class PedidosComponent {

  // configuracion de la tabla
  public summaryPosition: string = localStorage.getItem('summaryPosition') || 'bottom';
  public rowsLimitStr: string = localStorage.getItem('linTablas') || '10';
  public rowsLimit: number = parseInt(this.rowsLimitStr);

  // Decoradores para ordenar y paginación
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

    // this.dataSource.filter = 'naa-002';

    console.log('__Paginator:', this.paginator);
    console.log('__Sort:', this.sort);
  }

  // Intervalo de refresco de la tabla
  refreshInterval: any;
  timeRefreshPedidos: number = environment.timeRefreshPedidos;

  breadscrums = [{ title: 'Pedidos procesados', items: ['Inicio', 'Pedidos'], active: 'Procesados', }];
  loading: boolean = false;
  opcionesList: IListaOpciones = {}


  // Cargar más
  concatData: boolean = false;
  recDesde: number = 0;   // Desde dónde cargar
  recCuantos: number = parseInt(localStorage.getItem('recCuantosPedidos') || '500');   // Cuantos cargar
  recTotal: number = 0;   // Total de registros

  dataSource = new MatTableDataSource<IApiDataPedido>([]);
  displayedColumns: string[] = ['id', 'pedido_cli', 'fecha_pedidoExterno', 'camp_cod', 'tot_articulos', 'tot_referencias', 'nombre', 'poblacion', 'pais_iso', 'cod_servicio', 'estado', 'actions'];
  searchableColumns: string[] = ['id', 'pedido_cli', 'fecha_pedidoExterno', 'camp_cod', 'nombre', 'cp', 'poblacion', 'pais_iso', 'cod_servicio', 'estado'];

  // Filtros de estado
  public estados = [
    { value: '0', label: 'Todos', cantidad: 0 },
    { value: '1', label: 'Pendientes', cantidad: 0 },
    { value: '3', label: 'Completados', cantidad: 0 },
    { value: '4', label: 'Cancelados', cantidad: 0 },
    { value: '5', label: 'Servidos con faltas', cantidad: 0 },
    { value: '8', label: 'Incidencias', cantidad: 0 },
    { value: '99', label: 'Acción requerida ⚙️', cantidad: 0 },
  ];
  public estadoSelect: string = '0';

  constructor(
    private pedidosSrv: PedidosService,
    public almacenesSrv: AlmacenesService,
    private usuarioSrv: UsuarioService,
    private notificSrv: NotificacionesService,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {

    // Suscribirse a los parámetros de la url, si envían una id, cargar los datos del articulo
    this.activatedRoute.params.subscribe(({ buscar }) => {
      this.dataSource.filter = buscar;
    });

    this.getListaPedidos();

  }



  getListaPedidos() {
    this.opcionesList.id_cliente = this.usuarioSrv.userdata.cliente!.id
    this.opcionesList.estado = parseInt(this.estadoSelect) || 0
    this.opcionesList.totalesPorEstado = '1'
    this.opcionesList.recDesde = this.recDesde
    this.opcionesList.recCuantos = this.recCuantos

    this.loading = true;
    this.pedidosSrv.getLista(this.opcionesList)
      .subscribe(resp => {

        console.log('__getListaPedidos() opcionesList', this.opcionesList)
        console.log('__getListaPedidos() resp', resp)

        this.loading = false;
        if (resp.error) {
          this.notificSrv.aviso('error', resp.mensaje);
          return
        }

        this.recTotal = parseInt(resp.totales.totalRegistros);

        if (this.concatData) {
          this.dataSource.data = this.dataSource.data.concat(resp.data);
        } else {
          this.dataSource.data = resp.data
        }
        this.concatData = false;

        console.log('__getListaPedidos() recDesde: ', this.recDesde)
        console.log('__getListaPedidos() recTotal: ', this.recTotal)

        this.actualizarEstados(resp.totales.totalesPorEstado);
      })
  }


  cargaMas() {
    this.concatData = true;
    this.recDesde += this.recCuantos;
    if (this.recDesde > this.recTotal) this.recDesde = this.recTotal;

    console.log('__cargaMas() recDesde: ', this.recDesde)
    console.log('__cargaMas() recCuantos: ', this.recCuantos)
    console.log('__cargaMas() recTotal: ', this.recTotal)

    this.getListaPedidos();
  }

  // Buscar 
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();

    if (this.searchableColumns && this.searchableColumns.length > 0) {
      this.dataSource.filterPredicate = (data: any, filter: string) => {
        const searchableData = this.searchableColumns.map(campo => data[campo]).join(' ');
        return searchableData.toLowerCase().includes(filter);
      };
      this.dataSource.filter = filterValue.toLowerCase();
    }
  }


  highlightSearch(text: string, searchTerm: string): string {

    if (text == null) return '';
    if (!searchTerm) return text;

    const regex = new RegExp(searchTerm, 'gi');
    const highlightedText = text.replace(regex, match => `<span class="resaltado">${match}</span>`);
    return highlightedText;
  }


  // Totales
  getTotalReferencias(): number {
    return this.dataSource.filteredData.reduce((total, rec) => {
      const cantidadNum = rec.tot_referencias! || 0;      // Convertir la cantidad a un número antes de sumar
      return total + cantidadNum;
    }, 0);
  }

  getTotalArticulos(): number {
    return this.dataSource.filteredData.reduce((total, rec) => {
      const cantidadNum = rec.tot_articulos! || 0;      // Convertir la cantidad a un número antes de sumar
      return total + cantidadNum;
    }, 0);

  }


  descargaAlbaranClick(id_pedido: number) {
    console.log('__descargaAlbaranClick() id_pedido=', id_pedido);
    this.loading = true;
    this.pedidosSrv.downloadAlbaran(id_pedido)
      .subscribe(resp => {
        this.loading = false;
        if (resp.error) {
          this.notificSrv.aviso('error', resp.mensaje || 'Error desconocido');
          return
        }
        const urlPdf: string = environment.pdfDir + '/' + resp.data;
        window.open(urlPdf, '_blank');
      })
  }


  openTracking(id_pedido: number) {
    this.loading = true;
    console.log('__openTracking() id_pedido', id_pedido);

    this.pedidosSrv.getPedido(id_pedido)
      .subscribe(resp => {
        this.loading = false;
        console.log('__openTracking() resp.data', resp.data);

        if (resp.error) {
          this.notificSrv.aviso('error', resp.mensaje || 'Error desconocido');
          return
        }
        if (resp.data.ref_envio == null) {
          this.notificSrv.aviso('warning', 'No se ha encontrado referencia de envío para este pedido');
          return
        }

        const utlTracking = `https://clientes.innovacen.com/seg/${resp.data.cliNombre}/?ref=${resp.data.ref_envio}`;
        window.open(utlTracking, '_blank');
      })
  }


  estadoSelectChange() {
    this.recDesde = 0;
    this.recTotal = 0;
    this.getListaPedidos();
  }


  // Diálogos

  openDialogPedidoDetall(row: IApiDataPedido) {
    console.log('__openDialogPedidoDetall()', row)

    const dialogRef = this.dialog.open(PedidoDetallDialogComponent, {
      data: {
        pedido: row,
        action: 'edit',
      }
    });
  }


  openDialogNew() {
    // const dialogRef = this.dialog.open(PedidoNewDialogComponent, {
    //   data: {
    //     action: 'new',
    //   }
    // });
  }

  // Actualizar this.estados.cantidad con el número de registros 
  public actualizarEstados(totalesPorEstado: any) {
    this.estados.forEach(estado => {
      if (totalesPorEstado[estado.value]) {
        estado.cantidad = totalesPorEstado[estado.value];
      }
    });
  }


  /// AUX ///

  // export table data in excel file completo, cabeceras se repiten en cada línea
  exportExcel_Beta1() {
    const newArray = (this.dataSource.filteredData as any[]).flatMap((item) => {
      return item.detall.map((detalle: any) => {
        return {
          // Cabecera
          id_pedido: item.id,
          fecha_entrada: item.fecha_entrada,
          camp_cod: item.camp_cod,
          pedido_cli: item.pedido_cli,

          // Detall
          artSku: detalle.artSku,
          artEan: detalle.artEan,
          artDescripcion: detalle.artDescripcion,
          cantidad: detalle.cantidad,
          cant_repartir: detalle.cant_repartir,
          cant_repartida: detalle.cant_repartida
        };
      });
    });

    console.log(newArray)
  }


  // export table data in excel file completo, LAS cabeceras se repiten en cada línea
  exportExcel() {
    const exportData: Partial<TableElement>[] =
      (this.dataSource.filteredData as any[]).flatMap((item) => {
        return item.detall.map((detalle: any) => {
          return {
            // Cabecera
            'id pedido': parseInt(item.id),
            'Fecha pedido': formatDate(new Date(item.fecha_pedidoExterno!), 'dd/MM/yyyy HH:mm', 'es') || '',
            'Fecha entrada': formatDate(new Date(item.created_at!), 'dd/MM/yyyy HH:mm', 'es') || '',
            'Campaña': item.camp_cod,
            'Pedido': item.pedido_cli,

            // Detall
            'Referencia': detalle.artSku,
            'EAN': detalle.artEan,
            'Descripción': detalle.artDescripcion,
            'Cantidad': parseInt(detalle.cantidad) || 0,
            'Cant. a preparar': parseInt(detalle.cant_repartir) || 0,
            'Cant. preparada': parseInt(detalle.cant_repartida) || 0,
          };
        });
      });

    console.log(exportData)
    if (!exportData.length) {
      this.notificSrv.aviso('warning', 'No hay datos a exportar en XLSX')
      return
    }
    TableExportUtil.exportToExcel(exportData, 'Pedidos Procesados');
  }





}