<div class="row">
    <div class="col-12 mb-3">
        <div class="pedido-container mt-3">
            <div class="card shadow border-0 rounded-3 overflow-hidden">
                <div class="card-header text-dark py-2 d-flex align-items-center">
                    <div class="d-flex align-items-center justify-content-center me-2">
                        <span class="material-symbols-outlined text-primary">info</span>
                    </div>
                    <span class="fs-6 text-muted mt-2">Información sobre el origen del pedido</span>
                </div>
                <div class="card-body py-4">
                    <div class="row">
                        <div class="col-md-6 border-end pe-md-4">
                            <div class="info-section info-grid">
                                <div class="info-item subInfo mb-0">
                                    <strong class="info-label">Creado:</strong>
                                    <span class="info-value">{{creadoDesde}}</span>
                                </div>
                                <div class="info-item subInfo mb-1"
                                     *ngIf="pedidoExterno?.origen">
                                    <small class="info-label">Fichero de origen:</small>
                                    <small class="info-value">
                                        <span class="fichero">{{pedidoExterno?.origen}}</span> | Línea: <span class="badge bg-secondary">{{pedidoExterno?.origen_linea}}</span>
                                    </small>
                                </div>
                                <div *ngIf="!pedidoExterno?.origen"
                                     class="mb-1"></div>
                                <div class="info-item mb-0">
                                    <strong class="info-label">Fecha creación:</strong>
                                    <span class="info-value">{{pedidoExterno?.created_at | date: 'dd/MM/yyyy HH:mm'}} </span>
                                </div>
                                <div class="info-item mb-0">
                                    <strong class="info-label">Última modificación:</strong>
                                    <span class="info-value">{{pedidoExterno?.updated_at | date: 'dd/MM/yyyy HH:mm'}}</span>
                                </div>
                                <div class="info-item">
                                    <strong class="info-label">Fecha Carga:</strong>
                                    <span class="info-value">{{pedidoExterno?.fecha_carga | date: 'dd/MM/yyyy HH:mm'}}</span>
                                </div>
                                <div class="info-item subInfo mb-0">
                                    <strong class="info-label">eMail | Teléfono:</strong>
                                    <span class="info-value">{{pedidoExterno?.email}} | {{pedidoExterno?.telefono}}</span>
                                </div>
                                <div class="info-item subInfo mb-0">
                                    <strong class="info-label">Referencia envío:</strong>
                                    <span class="info-value">{{pedidoExterno?.ref_envio}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 ps-md-4">
                            <div class="info-section p-3 border rounded bg-light">
                                <div class="d-flex align-items-center mb-2">
                                    <div class="info-icon bg-white text-secondary rounded-circle me-2">
                                        <span class="material-symbols-outlined">inventory_2</span>
                                    </div>
                                    <strong class="info-label">Total Artículos:</strong>
                                    <span class="info-value fw-bold ms-2">{{pedidoExterno?.tot_articulos}}</span>
                                </div>
                                <div class="d-flex align-items-center mb-2">
                                    <div class="info-icon bg-white text-secondary rounded-circle me-2">
                                        <span class="material-symbols-outlined">category</span>
                                    </div>
                                    <strong class="info-label">Total Referencias:</strong>
                                    <span class="info-value fw-bold ms-2">{{pedidoExterno?.tot_referencias}}</span>
                                </div>
                            </div>
                            <div class="info-item subInfo mb-0">
                                <strong class="info-label">Enviar a:</strong>
                                <span class="info-value">{{pedidoExterno?.direccion}}<br>
                                    {{pedidoExterno?.cp}} {{pedidoExterno?.poblacion}} {{pedidoExterno?.pais_iso}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>