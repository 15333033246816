<section class="content">
    <div class="container-fluid">
        @for (breadscrum of breadscrums; track breadscrum) {
        <div class="block-header">

            <!-- breadcrumb -->
            <app-breadcrumb [title]="breadscrum.title"
                            [items]="breadscrum.items"
                            [active_item]="breadscrum.active">
            </app-breadcrumb>
        </div>
        }


        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="card">
                    <div class="materialTableHeader">
                        <div class="left">
                            <ul class="header-buttons-left ms-0">
                                <li class="tbl-title">
                                    <h2>Clientes</h2>
                                </li>

                                <li class="tbl-search-box">
                                    <label for="search-input"><i class="material-icons search-icon">search</i></label>
                                    <input (keyup)="applyFilter($event)"
                                           [(ngModel)]="dataSource.filter"
                                           matInput
                                           placeholder="Buscar"
                                           type="search"
                                           #filter
                                           class="browser-default search-field"
                                           aria-label="Search box">
                                </li>

                            </ul>
                        </div>
                        <div class="right">
                            <ul class="tbl-export-btn">

                                <li class="tbl-header-btn">
                                    <div class="m-l-10"
                                         matTooltip="Recargar datos">
                                        <button mat-mini-fab
                                                (click)="getListaClientesIntranet()"
                                                [disabled]="loading"
                                                color="primary">
                                            <mat-icon class="col-white">refresh</mat-icon>
                                        </button>
                                    </div>
                                </li>
                                <li>
                                    <div class="export-button m-l-10"
                                         matTooltip="Exportar a XLSX">
                                        <img src="assets/images/icons/xlsx.png"
                                             alt="Exportar a XLSX"
                                             (click)="exportExcel()" />
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>





                    <div class="body overflow-auto">
                        <div class="responsive_table">

                            <table mat-table
                                   [dataSource]="dataSource"
                                   matSort
                                   class="mat-elevation-z8 table table-hover">

                                <!--- Note that these columns can be defined in any order.
                                  The actual rendered columns are set as a property on the row definition" -->

                                <!-- id Column -->
                                <ng-container matColumnDef="id">
                                    <th mat-header-cell
                                        *matHeaderCellDef
                                        mat-sort-header
                                        class="text-end header_highlight"> id</th>
                                    <td mat-cell
                                        *matCellDef="let rec"
                                        class="text-end">
                                        <div [innerHTML]="highlightSearch(rec.id, dataSource.filter)"></div>
                                    </td>
                                    <td mat-footer-cell
                                        *matFooterCellDef></td>
                                </ng-container>

                                <!-- nombre Column -->
                                <ng-container matColumnDef="nombre">
                                    <th mat-header-cell
                                        *matHeaderCellDef
                                        mat-sort-header
                                        class="header_highlight"> Nombre </th>
                                    <td mat-cell
                                        *matCellDef="let rec">
                                        <div [innerHTML]="highlightSearch(rec.nombre, dataSource.filter)"></div>
                                    </td>
                                    <td mat-footer-cell
                                        *matFooterCellDef> <b>Total</b> </td>
                                </ng-container>


                                <!-- email Column -->
                                <ng-container matColumnDef="email">
                                    <th mat-header-cell
                                        *matHeaderCellDef
                                        mat-sort-header
                                        class="header_highlight"> eMail </th>
                                    <td mat-cell
                                        *matCellDef="let rec">
                                        <div [innerHTML]="highlightSearch(rec.email, dataSource.filter)"></div>
                                    </td>
                                    <td mat-footer-cell
                                        *matFooterCellDef> </td>
                                </ng-container>


                                <!-- telefono Column -->
                                <ng-container matColumnDef="telefono">
                                    <th mat-header-cell
                                        *matHeaderCellDef
                                        mat-sort-header
                                        class="header_highlight"> Teléfono </th>
                                    <td mat-cell
                                        *matCellDef="let rec">
                                        <div [innerHTML]="highlightSearch(rec.telefono, dataSource.filter)"></div>
                                    </td>
                                    <td mat-footer-cell
                                        *matFooterCellDef> </td>
                                </ng-container>

                                <!-- direccion Column -->
                                <ng-container matColumnDef="direccion">
                                    <th mat-header-cell
                                        *matHeaderCellDef
                                        mat-sort-header
                                        class="header_highlight"> Dirección </th>
                                    <td mat-cell
                                        *matCellDef="let rec">
                                        <div [innerHTML]="highlightSearch(rec.direccion, dataSource.filter)"></div>
                                    </td>
                                    <td mat-footer-cell
                                        *matFooterCellDef> </td>
                                </ng-container>

                                <!-- cp Column -->
                                <ng-container matColumnDef="cp">
                                    <th mat-header-cell
                                        *matHeaderCellDef
                                        mat-sort-header
                                        class="header_highlight"> CP </th>
                                    <td mat-cell
                                        *matCellDef="let rec">
                                        <div [innerHTML]="highlightSearch(rec.cp, dataSource.filter)"></div>
                                    </td>
                                    <td mat-footer-cell
                                        *matFooterCellDef> </td>
                                </ng-container>

                                <!-- poblacion Column -->
                                <ng-container matColumnDef="poblacion">
                                    <th mat-header-cell
                                        *matHeaderCellDef
                                        mat-sort-header
                                        class="header_highlight"> Población </th>
                                    <td mat-cell
                                        *matCellDef="let rec">
                                        <div [innerHTML]="highlightSearch(rec.poblacion, dataSource.filter)"></div>
                                    </td>
                                    <td mat-footer-cell
                                        *matFooterCellDef> </td>
                                </ng-container>

                                <!-- pais_iso Column -->
                                <ng-container matColumnDef="pais_iso">
                                    <th mat-header-cell
                                        *matHeaderCellDef
                                        mat-sort-header
                                        class="header_highlight"> País </th>
                                    <td mat-cell
                                        *matCellDef="let rec">
                                        <div [innerHTML]="highlightSearch(rec.pais_iso, dataSource.filter)"></div>
                                    </td>
                                    <td mat-footer-cell
                                        *matFooterCellDef> </td>
                                </ng-container>


                                <!-- created_at Column -->
                                <ng-container matColumnDef="created_at">
                                    <th mat-header-cell
                                        *matHeaderCellDef
                                        mat-sort-header
                                        class="header_highlight"> Entrada </th>
                                    <td mat-cell
                                        *matCellDef="let rec"> {{rec.created_at | date:'dd/MM/YYY'}} </td>
                                    <td mat-footer-cell
                                        *matFooterCellDef></td>
                                </ng-container>





                                <!-- actions -->
                                <ng-container matColumnDef="actions">
                                    <th mat-header-cell
                                        class="pr-0"
                                        *matHeaderCellDef
                                        class="header_highlight"></th>
                                    <td mat-cell
                                        *matCellDef="let row; let i=index;"
                                        class="pr-0">
                                        <span *ngIf="esAdmin()">
                                            <button mat-icon-button
                                                    (click)="$event.stopPropagation()"
                                                    (click)="deleteRec(row)"
                                                    [disabled]="loading"
                                                    class="tbl-action-btn">
                                                <app-feather-icons [icon]="'trash-2'"
                                                                   [class]="'tbl-fav-delete'"></app-feather-icons>
                                            </button>
                                        </span>
                                    </td>
                                    <td mat-footer-cell
                                        *matFooterCellDef></td>
                                </ng-container>






                                <!-- Mostrar las columnas Cabecera/conenido/footer -->
                                <tr mat-header-row
                                    *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row
                                    *matRowDef="let row; columns: displayedColumns;"></tr>
                                <tr mat-footer-row
                                    *matFooterRowDef="displayedColumns"></tr>

                                <!-- Sin datos -->
                                <div *matNoDataRow
                                     class="no-results">
                                    <span *ngIf="!loading">
                                        Sin datos...
                                    </span>
                                </div>


                            </table>



                            <!-- Loading spinner -->
                            <div class="tbl-spinner-container"
                                 *ngIf="loading">
                                <mat-progress-spinner color="primary"
                                                      [diameter]="40"
                                                      mode="indeterminate"></mat-progress-spinner>
                            </div>



                            <!-- Paginator -->
                            <span class="mt-3"> </span>
                            <mat-paginator #paginator
                                           [length]="dataSource.filteredData.length"
                                           [pageIndex]="0"
                                           [pageSize]="rowsLimit"
                                           [pageSizeOptions]="[5, 10, 25, 100]">
                            </mat-paginator>




                        </div>
                    </div>


                </div>
            </div>
        </div>
    </div>
</section>