/* eslint-disable @typescript-eslint/no-unused-vars */
import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from 'app/app.component';
import { appConfig } from 'app/app.config';
import { provideEnvironmentNgxMask } from 'ngx-mask';

bootstrapApplication(AppComponent, appConfig).catch((err) =>
  console.error(err)
);


// bootstrapApplication(AppComponent, {
//   providers: [
//     provideEnvironmentNgxMask(),
//   ],
// }).catch((err) => console.error(err));

