import { Component, Input } from '@angular/core';
import { FeatherIconsComponent } from '../feather-icons/feather-icons.component';
import { RouterLink } from '@angular/router';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  standalone: true,
  imports: [RouterLink, FeatherIconsComponent, CommonModule],
})
export class BreadcrumbComponent {
  @Input()
  title!: string; 
  @Input()
  items!: string[];
  @Input()
  active_item!: string;

  constructor() { }
}
