/* eslint-disable @typescript-eslint/no-explicit-any */
// Servicio para el Upload de ficheros tipo fotos ó pdf's
// tipo: 'users' | 'articulos' | 'clientes' | 'albaranEntrada' | 'docCliente')

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IApiResp } from '../interfaces/api-resp';
import { catchError, throwError } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})

export class UploadFileService {
  apiURL: string = environment.apiURL;
  private endPoint: string = '';

  constructor(
    private http: HttpClient,
    private router: Router,
  ) { }

  // Upload foto
  async uploadFoto(id: string, archivo: File, tipo: 'users' | 'articulos' | 'clientes' | 'albaranEntrada' | 'docCliente') {

    if (tipo === 'users' || tipo === 'articulos' || tipo === 'clientes') this.endPoint = 'imgUpload';
    if (tipo === 'albaranEntrada' || tipo === 'docCliente') this.endPoint = 'pdfUpload';

    try {
      const path = `${this.apiURL}/${this.endPoint}`;
      const formData = new FormData();
      formData.append('id', id);
      formData.append('tipo', tipo);
      formData.append('archivo', archivo);

      const resp = await fetch(path, {
        method: 'post',
        body: formData
      });

      const respAwa = await resp.json();
      return respAwa;

    } catch (error) {
      console.log('error en upload-file.service', error)
      return (false);
    }
  }

  // Borrar una foto
  imgDelete(tipo: 'users' | 'articulos' | 'clientes' | 'albaranEntrada' | 'docCliente', id: string) {
    const path = `${this.apiURL}/imgDelete/${tipo}/${id}`;
    return this.http.get<IApiResp>(path)
      .pipe(
        catchError(error => {
          return throwError(() => new Error(error));
        })
      )
  }




  // Upload un Xls 
  async uploadXls(archivo: File, tipo: 'articulos' | 'pedidosExternos' | 'otraCosa') {

    try {
      const path = `${this.apiURL}/uploadXls`;
      const formData = new FormData();
      formData.append('tipo', tipo);
      formData.append('archivo', archivo);

      const resp = await fetch(path, {
        method: 'post',
        body: formData
      });

      const respAwa = await resp.json();
      return respAwa;

    } catch (error) {
      console.log('error en uploadXls.service', error)
      return (false);
    }
  }


}
