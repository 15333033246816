<h2 mat-dialog-title>
    <mat-icon>warning</mat-icon>
    <span class="pb-1 "
          tabindex="0">
        Errores en la Importación
    </span>
    <button mat-icon-button
            (click)="cancelarClick()"
            class="modal-close-button "
            aria-label="Close dialog">
        <mat-icon>close</mat-icon>
    </button>
</h2>

<mat-dialog-content class="mat-typography">

    <div class="alert alert-danger"
         role="alert">
        No se han podido importar pedidos debido a los siguientes errores:
    </div>

    <div *ngFor="let linea of erroresArray">
        <h3 class="mb-1">Línea {{ linea.key }}:</h3>
        <ul>
            <div class="alert alert-secondary"
                 role="alert">
                <li *ngFor="let error of linea.value">
                    {{ error }}
                </li>
            </div>
        </ul>
    </div>


</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-raised-button
            [mat-dialog-close]="true">Cancelar</button>

</mat-dialog-actions>