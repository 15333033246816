<section class="content">
  <div class="container-fluid">
    @for (breadscrum of breadscrums; track breadscrum) {
    <div class="block-header">

      <!-- breadcrumb -->
      <app-breadcrumb [title]="breadscrum.title + almacenTitulo"
                      [items]="breadscrum.items"
                      [active_item]="breadscrum.active">
      </app-breadcrumb>
    </div>
    }


    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="card">
          <div class="materialTableHeader">
            <div class="left">
              <ul class="header-buttons-left ms-0">
                <li class="tbl-title">
                  <h2>Stock</h2>
                </li>

                <li class="tbl-search-box">
                  <label for="search-input"><i class="material-icons search-icon">search</i></label>
                  <input (keyup)="applyFilter($event)"
                         [(ngModel)]="dataSource.filter"
                         matInput
                         placeholder="Buscar"
                         type="search"
                         #filter
                         class="browser-default search-field"
                         aria-label="Search box">
                </li>

              </ul>
            </div>
            <div class="right">
              <ul class="tbl-export-btn">


                <!--  Múltiples almacenes -->
                <span *ngIf="almacenesList.length > 1">
                  <li class="tbl-title">
                    <h2 class="mr-1">Almacén</h2>
                  </li>

                  <li class="select-filtro">
                    <mat-form-field class="no-border "
                                    appearance="fill">
                      <mat-select [(ngModel)]="almSeleccionado"
                                  (selectionChange)="almacenSelectChange()">
                        <mat-option [value]="almacenTodos">
                          Todos
                        </mat-option>
                        <mat-option *ngFor="let almacen of almacenesList"
                                    [value]="almacen">
                          {{ almacen.nombre }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </li>
                </span>


                <li class="tbl-header-btn">
                  <div class="m-l-10"
                       matTooltip="Recargar datos">
                    <button mat-mini-fab
                            (click)="getListaStock()"
                            color="primary"
                            [disabled]="loading">
                      <mat-icon class="col-white">refresh</mat-icon>
                    </button>
                  </div>
                </li>

                <li class="tbl-header-btn">
                  <div class="m-l-10"
                       matTooltip="Descargar Transacciones">
                    <button mat-mini-fab
                            (click)="downloadTranacciones()"
                            color="primary"
                            [disabled]="loading">
                      <mat-icon class="col-white">download</mat-icon>
                    </button>
                  </div>
                </li>

                <li>
                  <div class="export-button m-l-10"
                       matTooltip="Exportar a XLSX">
                    <img src="assets/images/icons/xlsx.png"
                         alt=""
                         (click)="exportExcel()" />
                  </div>
                </li>
              </ul>
            </div>
          </div>






          <div class="body overflow-auto">
            <div class="responsive_table">

              <table mat-table
                     [dataSource]="dataSource"
                     matSort
                     class="mat-elevation-z8 table table-hover">

                <!--- Note that these columns can be defined in any order.
                      The actual rendered columns are set as a property on the row definition" -->

                <!-- id_articulo Column -->
                <ng-container matColumnDef="id_articulo">
                  <th mat-header-cell
                      *matHeaderCellDef
                      mat-sort-header
                      class="text-end header_highlight"> Artic.</th>
                  <td mat-cell
                      *matCellDef="let rec"
                      class="text-end">
                    <div [innerHTML]="highlightSearch(rec.id_articulo, dataSource.filter)"></div>
                  </td>
                  <td mat-footer-cell
                      *matFooterCellDef></td>
                </ng-container>


                <!-- foto -->
                <ng-container matColumnDef="foto">
                  <th mat-header-cell
                      *matHeaderCellDef
                      class="header_highlight"
                      [ngClass]="'tbl-col-width-per-6'">
                  </th>
                  <td mat-cell
                      *matCellDef="let row"
                      (click)="$event.stopPropagation()"
                      class="table-img tbl-col-width-per-6 ">
                    <img *ngIf="row.articulo.foto !== '_noFoto.png'"
                         [src]="fotoDir +'/articulos' + imgResized + row.articulo.foto"
                         loading="lazy"
                         class="thumbnail"
                         (click)="abrirPopupImagen(fotoDir + '/articulos' + imgResized + row.articulo.foto)" />
                  </td>
                  <td mat-footer-cell
                      *matFooterCellDef></td>
                </ng-container>



                <!-- articuloSku Column -->
                <ng-container matColumnDef="articuloSku">
                  <th mat-header-cell
                      *matHeaderCellDef
                      mat-sort-header
                      class="header_highlight"> SKU </th>
                  <td mat-cell
                      *matCellDef="let rec">
                    <div [innerHTML]="highlightSearch(rec.articuloSku, dataSource.filter)"></div>
                  </td>
                  <td mat-footer-cell
                      *matFooterCellDef> <b>Total</b> </td>
                </ng-container>

                <!-- ean Column -->
                <ng-container matColumnDef="articuloEan">
                  <th mat-header-cell
                      *matHeaderCellDef
                      mat-sort-header
                      class="header_highlight"> EAN </th>
                  <td mat-cell
                      *matCellDef="let rec">
                    <div [innerHTML]="highlightSearch(rec.articuloEan, dataSource.filter)"></div>
                  </td>
                  <td mat-footer-cell
                      *matFooterCellDef></td>
                </ng-container>

                <!-- descripcion Column -->
                <ng-container matColumnDef="descripcion">
                  <th mat-header-cell
                      *matHeaderCellDef
                      mat-sort-header
                      class="header_highlight"> Descripción </th>
                  <td mat-cell
                      *matCellDef="let rec">
                    <div [innerHTML]="highlightSearch( funcLib.limitarYAgregarPuntos( rec.articulo.descripcion,75), dataSource.filter)"></div>
                  </td>
                  <td mat-footer-cell
                      *matFooterCellDef></td>
                </ng-container>

                <!-- lote Column -->
                <ng-container matColumnDef="lote">
                  <th mat-header-cell
                      *matHeaderCellDef
                      mat-sort-header
                      class="header_highlight"> lote </th>
                  <td mat-cell
                      *matCellDef="let rec">
                    <div [innerHTML]="highlightSearch(rec.lote, dataSource.filter)"></div>
                  </td>
                  <td mat-footer-cell
                      *matFooterCellDef></td>
                </ng-container>


                <!-- fechaCaducidad Column -->
                <ng-container matColumnDef="fechaCaducidad">
                  <th mat-header-cell
                      *matHeaderCellDef
                      mat-sort-header
                      class="header_highlight"> Caducidad </th>
                  <td mat-cell
                      *matCellDef="let rec">

                    <ng-container *ngIf="isExpired(rec.fechaCaducidad); else elseBlock">
                      <span class="mat-badge-wrapper p-1"
                            matBadge="!"
                            matBadgeSize="small"
                            matBadgeColor="accent"
                            matTooltip="Caducado">
                        <span [innerHTML]="highlightSearch(rec.fechaCaducidad, dataSource.filter)"></span>
                      </span>
                    </ng-container>

                    <ng-template #elseBlock>
                      <span [innerHTML]="highlightSearch(rec.fechaCaducidad, dataSource.filter)"></span>
                    </ng-template>


                    <!-- {{rec.fechaCaducidad | date:'dd/MM/YYY'}} -->
                  </td>
                  <td mat-footer-cell
                      *matFooterCellDef></td>
                </ng-container>



                <!-- ubiCodigo Column -->
                <ng-container matColumnDef="ubiCodigo">
                  <th mat-header-cell
                      *matHeaderCellDef
                      mat-sort-header
                      class="header_highlight"> Ubicación </th>
                  <td mat-cell
                      *matCellDef="let rec"
                      style="cursor: help;"
                      [matTooltip]="funcLib.CodUbiDecript(rec.ubiCodigo)">
                    <div [innerHTML]="highlightSearch(rec.ubiCodigo, dataSource.filter)"></div>
                  </td>
                  <td mat-footer-cell
                      *matFooterCellDef></td>
                </ng-container>

                <!-- fechaEntrada Column -->
                <ng-container matColumnDef="fechaEntrada">
                  <th mat-header-cell
                      *matHeaderCellDef
                      mat-sort-header
                      class="header_highlight"> Entrada </th>
                  <td mat-cell
                      *matCellDef="let rec"> {{rec.fechaEntrada | date:'dd/MM/YYY'}} </td>
                  <td mat-footer-cell
                      *matFooterCellDef></td>
                </ng-container>

                <!-- cantidad Column -->
                <ng-container matColumnDef="cantidad">
                  <th mat-header-cell
                      *matHeaderCellDef
                      mat-sort-header
                      class="text-end header_highlight"> Stock </th>
                  <td mat-cell
                      *matCellDef="let rec"
                      class="text-end"> {{rec.cantidad | number: '0.0-0':'es' }} </td>
                  <td mat-footer-cell
                      *matFooterCellDef
                      class="text-end"> <b>{{getTotalCantidad() | number: '0.0-0':'es' }}</b> </td>
                </ng-container>

                <!-- cantidadReservada Column -->
                <ng-container matColumnDef="cantidadReservada">
                  <th mat-header-cell
                      *matHeaderCellDef
                      class="text-end header_highlight"> Reservado </th>
                  <td mat-cell
                      *matCellDef="let rec"
                      class="text-end"> {{rec.cantidadReservada | number: '0.0-0':'es' }} </td>
                  <td mat-footer-cell
                      *matFooterCellDef
                      class="text-end"> <b>{{getTotalResvada() | number: '0.0-0':'es' }}</b> </td>
                </ng-container>

                <!-- cantidadDisponible Column -->
                <ng-container matColumnDef="cantidadDisponible">
                  <th mat-header-cell
                      *matHeaderCellDef
                      class="text-end header_highlight"> Disponible </th>
                  <td mat-cell
                      *matCellDef="let rec"
                      class="text-end"> {{rec.cantidadDisponible | number: '0.0-0':'es' }} </td>
                  <td mat-footer-cell
                      *matFooterCellDef
                      class="text-end"> <b>{{getTotalDisponible() | number: '0.0-0':'es' }}</b> </td>
                </ng-container>



                <!-- Mostrar las columnas Cabecera/conenido/footer -->
                <tr mat-header-row
                    *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row
                    *matRowDef="let row; columns: displayedColumns;"></tr>
                <tr mat-footer-row
                    *matFooterRowDef="displayedColumns"></tr>

                <!-- Sin datos -->
                <div *matNoDataRow
                     class="no-results">
                  <span *ngIf="!loading">
                    Sin datos...
                  </span>
                </div>


              </table>



              <!-- Loading spinner -->
              <div class="tbl-spinner-container"
                   *ngIf="loading">
                <mat-progress-spinner color="primary"
                                      [diameter]="40"
                                      mode="indeterminate"></mat-progress-spinner>
              </div>



              <!-- Paginator -->
              <span class="mt-3"> </span>
              <mat-paginator #paginator
                             [length]="dataSource.filteredData.length"
                             [pageIndex]="0"
                             [pageSize]="rowsLimit"
                             [pageSizeOptions]="[5, 10, 25, 100]">
              </mat-paginator>




            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>





<!-- Modal para mostrar la imagen -->
<div class="modal-overlay"
     *ngIf="imagenSeleccionada"
     (click)="cerrarPopupImagen()">
  <div class="modal-content">
    <img [src]="imagenSeleccionada"
         class="modal-image"
         alt="Imagen seleccionada">
  </div>
</div>