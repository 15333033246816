<div class="addContainer">

    <div class="modalHeader">
        <div class="editRowModal">
            <div class="modalHeader clearfix">
                <img src='../../../../assets/images/trello.svg'
                     alt="Solicitud de entrada InnovaCen">
                <div class="modal-about">
                    Entrada de artículos<span class="text-success me-2"> {{dialogTitle}}</span>
                    <small>{{entrada.created_at | date: 'MM/dd/yyyy'}}</small>
                </div>
            </div>
        </div>
        <button mat-icon-button
                (click)="cancelarClick()"
                class="modal-close-button"
                aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </div>

    <div mat-dialog-content>
        <form class="m-1"
              [formGroup]="cabeceraForm"
              #formularioCabecera
              (ngSubmit)="enviar()">

            <div class="row">


                <!-- proveedor -->
                <div class="col-2">
                    <mat-form-field class="example-full-width mb-1"
                                    appearance="outline">
                        <mat-label>Proveedor</mat-label>
                        <input matInput
                               type="text"
                               formControlName="proveedor"
                               maxlength="30">
                        <mat-error *ngIf="f['proveedor'].hasError('required')">Oligatorio</mat-error>
                        <mat-error *ngIf="f['proveedor'].hasError('minlength')">Mínimo 4 caracteres</mat-error>

                        <mat-error *ngIf="cabeceraForm.get('proveedor')?.errors?.['apiError']">{{
                            cabeceraForm.get('proveedor')?.errors?.['apiError'] }}</mat-error>

                    </mat-form-field>
                </div>


                <!-- albaran -->
                <div class="col-4"
                     style="display: flex; align-items: center;">
                    <mat-form-field class="example-full-width mb-1"
                                    appearance="outline"
                                    style="flex: 1;">
                        <mat-label>Albarán</mat-label>
                        <input matInput
                               type="text"
                               formControlName="albaran"
                               (blur)="toUpper('albaran')"
                               maxlength="30">

                        <mat-error *ngIf="f['albaran'].hasError('required')">Oligatorio</mat-error>
                        <mat-error *ngIf="f['albaran'].hasError('minlength')">Mínimo 4 caracteres</mat-error>

                        <mat-error *ngIf="cabeceraForm.get('albaran')?.errors?.['apiError']">{{
                            cabeceraForm.get('albaran')?.errors?.['apiError'] }}</mat-error>

                    </mat-form-field>

                    <!-- Iconos pdf albarán -->
                    <div class="icon-container">
                        <button mat-mini-fab
                                (click)="$event.stopPropagation()"
                                (click)="abrirModalAlbaran()"
                                [disabled]="loading"
                                type="button"
                                matTooltip="Subir PDF del albarán"
                                color="primary"
                                class="me-2"
                                style="vertical-align: super;">
                            <app-feather-icons [icon]="'upload'"
                                               [class]="'col-white'"></app-feather-icons>

                        </button>
                        <button *ngIf="entrada.pdf_albaran"
                                mat-mini-fab
                                (click)="$event.stopPropagation()"
                                (click)="descargaAlbaranClick(entrada)"
                                [disabled]="loading"
                                type="button"
                                color="primary"
                                matTooltip="Abrir PDF del albarán"
                                class="me-2"
                                style="vertical-align: super;">
                            <app-feather-icons [icon]="'file-text'"
                                               [class]="'col-white'"></app-feather-icons>
                        </button>
                        <button *ngIf="entrada.pdf_albaran"
                                mat-mini-fab
                                (click)="$event.stopPropagation()"
                                (click)="borrarPDF(entrada)"
                                [disabled]="loading"
                                type="button"
                                matTooltip="Borrar PDF del albarán"
                                class="me-2"
                                style="vertical-align: super;"
                                color="primary">
                            <app-feather-icons [icon]="'trash-2'"
                                               [class]="'col-white'"></app-feather-icons>
                        </button>

                    </div>
                </div>


                <!-- devolucion -->
                <div class="col-3">
                    <mat-checkbox formControlName="devolucion">Entrada por Devolución
                    </mat-checkbox>
                </div>


                <!-- fac_pales -->
                <div class="col-2">
                    <mat-form-field class="example-full-width"
                                    appearance="outline"
                                    matTooltip="Núnero de palés a descargar en esta entrada">
                        <mat-label>Palés</mat-label>
                        <mat-select formControlName="fac_pales"
                                    required>
                            <mat-option value="0">Sin paletizar</mat-option>
                            <mat-option *ngFor="let num of ['1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31','32','33']"
                                        [value]="num">
                                {{ num }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>




            </div> <!-- row -->




            <div class="row">

                <!-- Botones -->
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">

                    <div class="example-button-row">
                        <button mat-raised-button
                                color="primary"
                                type="submit"
                                [disabled]="!cabeceraForm.valid"
                                [disabled]="loading">
                            {{txtSubmit}}
                        </button>

                        <button mat-raised-button
                                type="button"
                                color="warn"
                                (click)="cancelarClick()"
                                [disabled]="loading"
                                [mat-dialog-close]="1"
                                tabindex="-1">
                            Cancelar
                        </button>

                        <button mat-raised-button
                                class="ml-nuevoArticulo text-success"
                                type="button"
                                matTooltip="Crear un nuevo artículo y añadirlo a la lista"
                                (click)="openDialogArticuloNew()"
                                [disabled]="loading">
                            <img src='../../../../assets/images/trello.svg'
                                 alt="Artículo nuevo"
                                 class="img-light"
                                 style="width: 20px; height: 20px;">
                            Artículo nuevo
                        </button>

                        <div class="right">

                            <ul class="tbl-export-btn">

                                <div class="m-l-1"
                                     style="display: inline-flex; align-items: center;">

                                    <button mat-mini-fab
                                            matTooltip="Añadir línea"
                                            type="button"
                                            color="primary"
                                            (click)="addLineaDetall()"
                                            [disabled]="loading">
                                        <mat-icon class="col-white">add</mat-icon>
                                    </button>

                                    <!-- Contador de líneas  -->
                                    <span class="mb-1 badge"
                                          [ngClass]="{'badge-solid-green': arrayDetalleForm.length > 0, 'badge-solid-orange': arrayDetalleForm.length === 0}">
                                        {{arrayDetalleForm.length}} Referencias
                                    </span>

                                </div>

                            </ul>
                        </div>



                    </div>
                </div>

            </div> <!-- row -->



        </form>





        <!-- Formularios de Detalle sobre arrayDetalleForm -->
        <form *ngFor="let detalleForm of arrayDetalleForm; let i = index"
              [formGroup]="detalleForm"
              #formularioDetalle>

            <div class="row">


                <!-- id_articulo -->
                <div [ngClass]="{'col-5':fd(i, 'artSwLote')?.value === '1', 'col-9': fd(i, 'artSwLote')?.value !== '1'}">
                    <div id="ngSelectContainer">

                        <ng-select class="example-full-width "
                                   [class.error]="enviado && fd(i, 'id_articulo')?.invalid"
                                   formControlName="id_articulo"
                                   appearance="outline"
                                   [searchable]="true"
                                   [clearable]="true"
                                   dropdownPosition="top"
                                   labelForId="id_articulo"
                                   (change)="cambiaArticulo($event, i)"
                                   placeholder="Artículo {{i+1}}"
                                   [items]="articulosList"
                                   bindLabel="label txt"
                                   bindValue="id"
                                   [searchFn]="customSearchFnArticulo"
                                   notFoundText="Ningún artículo encontrado"
                                   clearAllText="Clear"
                                   required>

                            <ng-template ng-label-tmp
                                         let-item="item">
                                <span *ngIf="item.descripcion">{{ item.ean + ' (' + item.descripcion + ')' }}</span>
                            </ng-template>

                            <ng-template ng-option-tmp
                                         let-item="item"
                                         let-search="searchTerm"
                                         let-index="index">
                                <span [ngOptionHighlight]="search">{{ item.ean + ' - ' + item.sku + ' (' + item.descripcion + ')' }}</span>
                            </ng-template>

                        </ng-select>

                    </div>
                </div>



                <!-- lote -->
                <div *ngIf="fd(i, 'artSwLote')?.value==='1'"
                     class=" col-md-2">
                    <mat-form-field class="example-full-width"
                                    appearance="outline">
                        <mat-label>Lote</mat-label>
                        <input matInput
                               type="text"
                               (blur)="onLoteBlur(i)"
                               formControlName="lote">
                        <mat-error *ngIf="fd(i, 'lote')?.hasError('required')">Este artículo gestiona lotes</mat-error>
                    </mat-form-field>
                </div>



                <!-- fecha_caducidad -->
                <div *ngIf="fd(i, 'artSwLote')?.value==='1'"
                     class="col-md-2">
                    <mat-form-field class="example-full-width"
                                    appearance="outline"
                                    matBadge="!"
                                    matBadgeColor="warn"
                                    [matBadgeHidden]="!isExpired(i)"
                                    [matTooltip]="isExpired(i) ? 'Caducado' :''"
                                    matTooltipShowDelay="200">
                        <mat-label>Caducidad</mat-label>
                        <input matInput
                               [matDatepicker]="picker"
                               formControlName="fecha_caducidad"
                               [ngClass]="{'readOnly': readOnlyFecCaducidad[i] === true}"
                               [readonly]="readOnlyFecCaducidad[i] === true"
                               (dateChange)="fechaCaducidadChange(i)"
                               [matTooltip]="readOnlyFecCaducidad[i] === true ? 'No se puede editar la fecha de caducidad, existe Stock de este lote' : ''"
                               matTooltipShowDelay="200">
                        <mat-datepicker-toggle matSuffix
                                               [for]="picker"
                                               [ngClass]="{'readOnly': readOnlyFecCaducidad[i] === true}"
                                               [disabled]="readOnlyFecCaducidad[i] === true">
                        </mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                        <mat-error *ngIf="fd(i, 'fecha_caducidad')?.hasError('required')">Caducidad Obligatioria en lotes</mat-error>
                    </mat-form-field>
                </div>





                <!-- foto -->
                <div class="col-md-1 table-img ">
                    <div *ngIf="fd(i, 'artFoto')?.value"
                         class="mt-4 imgArtic">
                        <img [src]="fotoDir +'/articulos' + imgResized +fd(i, 'artFoto')?.value"
                             loading="lazy"
                             [class.thumbnail]="fd(i, 'artFoto')?.value !== '_noFoto.png'"
                             (click)="abrirPopupImagen(fotoDir +'/articulos' + imgResized + fd(i, 'artFoto')?.value)" />
                    </div>
                </div>



                <!-- cantidad -->
                <div class=" col-md-1">
                    <mat-form-field class="example-full-width"
                                    appearance="outline">
                        <mat-label>Cant.</mat-label>
                        <input matInput
                               type="text"
                               class="text-right"
                               mask="separator"
                               formControlName="cantidad">
                        <mat-error *ngIf="fd(i, 'cantidad')?.hasError('numEntero')">Debe ser un entero positivo</mat-error>
                    </mat-form-field>
                </div>



                <!-- Eliminar la línea -->
                <div class="col-1">
                    <button mat-icon-button
                            (click)="$event.stopPropagation()"
                            (click)="deleteLineaDetall(i)"
                            [disabled]="loading"
                            matTooltip="Eliminar esta línea"
                            class="tbl-action-btn">
                        <app-feather-icons [icon]="'trash-2'"
                                           [class]="'tbl-fav-delete'"></app-feather-icons>
                    </button>
                </div>


            </div><!-- row -->

        </form>


    </div>
</div>



<!-- Modal para mostrar la imagen -->
<div class="modal-overlay"
     *ngIf="imagenSeleccionada"
     (click)="cerrarPopupImagen()">
    <div class="modal-content">
        <img [src]="imagenSeleccionada"
             class="modal-image"
             alt="Imagen seleccionada">
    </div>
</div>