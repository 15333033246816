/* eslint-disable @typescript-eslint/no-explicit-any */

// core
import { DatePipe, CommonModule } from '@angular/common';
import { Component, LOCALE_ID, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { OnInit } from '@angular/core';

// material
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginator, MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MaterialModule } from 'app/auth/material/material.module';

// componentes
import { BreadcrumbComponent } from '@shared/components/breadcrumb/breadcrumb.component';
import { FeatherIconsComponent } from '@shared/components/feather-icons/feather-icons.component';

//servicios  
import { UsuarioService } from 'app/services/datos/usuario.service';
import { NotificacionesService } from 'app/services/notificaciones.service';
import { CustomPaginatorIntl, } from 'app/services/custom-paginator.service';
import { DatosAuxService } from 'app/services/datos/datosAux.service';

//interfaces  
import { IClienteIntranet } from 'app/interfaces/clienteIntranet';

// librerías
import { UnsubscribeOnDestroyAdapter, TableElement, TableExportUtil } from '@shared';
import { environment } from 'environments/environment';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';

// constantes
import * as Constantes from '../shared/constants';


@Component({
  selector: 'app-clientes',
  standalone: true,
  providers: [DatePipe, { provide: LOCALE_ID, useValue: 'es' }, { provide: MatPaginatorIntl, useClass: CustomPaginatorIntl }],
  imports: [CommonModule, FormsModule, BreadcrumbComponent, MaterialModule, MatTableModule, FeatherIconsComponent,
    MatSortModule, MatPaginatorModule, MatFormFieldModule, MatInputModule],
  templateUrl: './clientes.component.html',
  styleUrl: './clientes.component.scss'
})
export class ClientesComponent extends UnsubscribeOnDestroyAdapter implements OnInit {

  //Usar constantes en el template
  Constantes = Constantes;

  // configuracion de la tabla
  public summaryPosition: string = localStorage.getItem('summaryPosition') || 'bottom';
  public rowsLimitStr: string = localStorage.getItem('linTablas') || '10';
  public rowsLimit: number = parseInt(this.rowsLimitStr);

  // Decoradores para ordenar y paginación
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;


  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

    console.log('__Paginator:', this.paginator);
    console.log('__Sort:', this.sort);
  }

  public imgSubs: Subscription | undefined;
  public fotoDir: string = environment.fotoDir;
  public imgResized: string = localStorage.getItem('imgResized') || '/resize/';

  breadscrums = [{ title: 'Listado de clientes', items: ['Inicio'], active: 'Clientes', }];
  loading: boolean = false;

  displayedColumns: string[] = ['id', 'nombre', 'email', 'telefono', 'direccion', 'cp', 'poblacion', 'pais_iso', 'created_at', 'actions'];
  searchableColumns: string[] = ['id', 'nombre', 'email', 'telefono', 'direccion', 'cp', 'poblacion', 'pais_iso'];

  dataSource = new MatTableDataSource<IClienteIntranet>([]);


  constructor(
    private datosAuxSrv: DatosAuxService,
    public usuarioSrv: UsuarioService,
    private notificSrv: NotificacionesService,
    public dialog: MatDialog,
    private datePipe: DatePipe,
  ) {
    super();
  }

  ngOnInit(): void {
    console.log('__OnInit() this.usuarioSrv.userdata.rolIntranet: ', this.usuarioSrv.userdata.rolIntranet);
    this.getListaClientesIntranet();
  }


  getListaClientesIntranet() {
    const id_cliente = this.usuarioSrv.userdata.cliente!.id;

    this.loading = true;
    this.datosAuxSrv.getListaClientesIntranet(id_cliente)
      .subscribe(resp => {
        this.loading = false;
        if (resp.error) {
          this.notificSrv.aviso('error', resp.mensaje);
          return
        }

        this.dataSource.data = resp.data;
        console.log('__getListaClientesIntranet() ', resp)
      })
  }




  // Buscar 
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();

    if (this.searchableColumns && this.searchableColumns.length > 0) {
      this.dataSource.filterPredicate = (data: any, filter: string) => {
        const searchableData = this.searchableColumns.map(campo => data[campo]).join(' ');
        return searchableData.toLowerCase().includes(filter);
      };
      this.dataSource.filter = filterValue.toLowerCase();
    }
  }



  highlightSearch(text: string, searchTerm: string): string {

    if (text == null) return '';
    if (!searchTerm) return text;

    const regex = new RegExp(searchTerm, 'gi');
    const highlightedText = text.replace(regex, match => `<span class="resaltado">${match}</span>`);
    return highlightedText;
  }


  transformDate(date: string | null): string {
    //  return date !== null ? this.datePipe.transform(date, 'shortDate') ?? '' : '';    
    if (!date) return '';
    return date !== null ? this.datePipe.transform(date, 'yyyy-MM-dd') ?? '' : '';
  }





  // Borrar
  deleteRec(clientIntranet: IClienteIntranet) {
    console.log('__deleteRec() ', clientIntranet)
    if (!this.esAdmin()) return;
    Swal.fire({
      title: 'Borrar datos de cliente',
      icon: 'warning',
      html: `¿Eliminar el cliente ${clientIntranet.nombre}? <br>
      <small style="font-size: smaller; font-style: italic;">Estos registros se crean automaticamente desde la creación de pedidos</small>`,
      showCancelButton: true,
      cancelButtonText: 'Volver',
      confirmButtonText: 'Si',
    }).then((result) => {
      if (result.isConfirmed) {
        this.datosAuxSrv.delClientIntranet(clientIntranet.id!).subscribe(resp => {
          console.log('pedidosExtSrv.delete', resp)
          if (resp.error) {
            this.notificSrv.aviso('error', resp.mensaje)
            return
          }

          this.notificSrv.aviso('info', `Cliente ${clientIntranet.nombre} borrado`)
          this.getListaClientesIntranet();
          return
        })

      }
    })

  }





  /// AUX /// 

  // Devuelve Si el usuario actual es administrador
  esAdmin(): boolean {
    return this.usuarioSrv.userdata.rolIntranet === 'admin';
  }

  // export table data in excel file
  exportExcel() {
    const exportData: Partial<TableElement>[] =
      this.dataSource.filteredData.map((x) => ({
        'Nombre y apellidos': x.nombre,
        'eMail': x.email,
        'Teléfono': x.telefono,
        'Dirección': x.direccion,
        'CP': x.cp,
        'Población': x.poblacion,
        'País': x.pais_iso,
        'Creado el': x.created_at,
      }));

    console.log('exportData', exportData)
    if (!exportData.length) {
      this.notificSrv.aviso('warning', 'No hay datos a exportar en XLSX')
      return
    }
    TableExportUtil.exportToExcel(exportData, 'Clientes');

  }





}