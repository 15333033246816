/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, } from '@angular/common/http';
import { Injectable } from '@angular/core';

// interfaces
import { IListaOpciones } from 'app/interfaces/lista-opciones';
import { IApiResp } from "app/interfaces/api-resp";
import { IArticulo } from 'app/interfaces/articulo';

// environment y librerías
import { environment } from 'environments/environment';


@Injectable({
  providedIn: 'root'
})
export class StockService {

  apiURL: string = environment.apiURL;

  constructor(
    private http: HttpClient,
  ) { }


  // Devulve la lista de stock filtrada
  getLista(_opciones: IListaOpciones = {}) {
    // Devulve la lista filtrada
    const path = `${this.apiURL}/stock/listFiltro`;
    return this.http.post<IApiResp>(path, _opciones)
  }


  // Devuelve los datos del stock para un artículo y lote
  stockLote(_datos: {
    "id_articulo": number,
    "lote": string
  }) {
    const path = `${this.apiURL}/stock/stockLote`;
    return this.http.post<IApiResp>(path, _datos)
  }


  // Actualiza fechaCaducidad del stock para registros con un artículo y lote
  UpdateFechaCaducidad(_datos: {
    "id_articulo": number,
    "lote": string,
    "fechaCaducidad": Date
  }) {
    const path = `${this.apiURL}/stock/UpdateFechaCaducidad`;
    return this.http.post<IApiResp>(path, _datos)
  }


  // Devulve 1 registro
  getRec(id: number) {
    const path = `${this.apiURL}/stock/list/${id}`;
    const resp = this.http.get<IApiResp>(path)
    return resp;
  }


  // Nuevo
  newRec(formData: any) {
    const path = `${this.apiURL}/stock/new`;
    return this.http.post<IApiResp>(path, formData)
  }

  // Actualiza
  update(articulo: IArticulo) {
    const path = `${this.apiURL}/stock/update/${articulo.id}`;
    return this.http.post<IApiResp>(path, articulo)
  }

  // Elimina
  delete(id: number) {
    const path = `${this.apiURL}/stock/delete/${id}`;
    return this.http.get<IApiResp>(path)
  }

  // Mueve stock
  stockMueve(datos: any) {
    const path = `${this.apiURL}/stock/stockMueve`;
    return this.http.post<IApiResp>(path, datos)
  }


  /**
   * getAlmacenesCliente(id_cli)
   * Devuelve los almacenes donde un cliente tiene STOCK
   */
  getAlmacenesCliente(id_cli: number) {
    const path = `${this.apiURL}/stock/getAlmacenesCliente/${id_cli}`;
    return this.http.get<IApiResp>(path)
  }



}
