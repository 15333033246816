/* eslint-disable @typescript-eslint/no-explicit-any */
import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MaterialModule } from 'app/auth/material/material.module';



@Component({
  selector: 'app-errores-modal-ped-ext',
  standalone: true,
  imports: [CommonModule, MaterialModule, MatCardModule, MatButtonModule],
  templateUrl: './errores-modal-ped-ext.component.html',
  styleUrl: './errores-modal-ped-ext.component.scss'
})
export class ErroresModalPedExtComponent {
  erroresArray: { key: string; value: string[]; }[] | undefined;


  constructor(
    @Inject(MAT_DIALOG_DATA) public errores: { [key: number]: string[] },
    private dialogRef: MatDialogRef<ErroresModalPedExtComponent>
  ) {
    this.erroresArray = Object.entries(this.errores).map(([key, value]) => ({ key, value }));
  }

  cancelarClick() {
    this.dialogRef.close();
  }
}